import {
  Button,
  ContentContainer,
  LoadingSpinner,
  Popup,
} from '@components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { StoreContext } from '@components/App'
import { SignDocumentSingle } from '@components/SignDocument/SignDocumentSingle'
import { SignDocumentMultiple } from '@components/SignDocument/SignDocumentMultiple'
import { ScrollDocumentPopupBody, Spacer } from '@styles/generic.styles'
import { ContentToRight } from '@styles/layout.styles'
import { useSignDocumentPolling } from '@/hooks/useSignDocumentPolling'

export const SignDocumentPage = observer(() => {
  const store = useContext(StoreContext)
  const { signDocumentPage: trans } = store.TranslationsState.translations

  const { theme } = store.InterfaceState
  const { currentScenarioId, context } = store.ScenarioState

  const [signed, setSigned] = useState(false)
  const [signedSantander, setSignedSantander] = useState(false)
  const [containerHeight, setContainerHeight] = useState('')
  const [infoPopupVisible, setInfoPopupVisible] = useState(
    context?.forceScrollingBeforeDocumentAccept
  )

  const checkboxSantanderEnabled = context.configurationId === 'santander.it'

  const { isLoading, isDocReady, handleSign, singleDoc, docs, btnLoading } =
    useSignDocumentPolling(currentScenarioId, 1000)

  return (
    <ContentContainer
      {...theme.container}
      width="1000px"
      minHeight="250px"
      paddingMobile="20px"
      heightMobile={containerHeight}
      marginMobile="0px"
    >
      {isLoading || !isDocReady ? (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      ) : (
        <>
          {singleDoc?.url?.length > 0 && (
            <SignDocumentSingle
              signed={signed}
              setSigned={setSigned}
              {...(checkboxSantanderEnabled && {
                ...{
                  signedSantander: signedSantander,
                  setSignedSantander: setSignedSantander,
                },
              })}
              doc={singleDoc}
              handleSign={handleSign}
              btnLoading={btnLoading}
              setContainerHeight={setContainerHeight}
              trans={trans}
            />
          )}

          {docs?.length > 0 && (
            <SignDocumentMultiple
              signed={signed}
              setSigned={setSigned}
              docs={docs}
              handleSign={handleSign}
              btnLoading={btnLoading}
              setContainerHeight={setContainerHeight}
              trans={trans}
            />
          )}
        </>
      )}

      <Popup
        visibility={infoPopupVisible}
        handleVisibility={setInfoPopupVisible}
        {...theme.popup}
        width="420px"
        mobileWidth="324px"
        height="auto"
        mobileHeight="auto"
      >
        <ScrollDocumentPopupBody>
          {trans.popupDescription}
        </ScrollDocumentPopupBody>
        <Spacer height="20px" />
        <ContentToRight>
          <Button
            onClick={() => setInfoPopupVisible(false)}
            {...theme.button}
            margin="20px 0 0"
          >
            {trans.popupButton}
          </Button>
        </ContentToRight>
      </Popup>
    </ContentContainer>
  )
})

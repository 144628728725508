import { useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { handlePhonePrefix, KEYS } from '@/methods/videoIdPhoneHandler'
import { axiosInstance } from '@/methods/axiosConfig'
import { devLog } from '@/methods/devLog'
import { apiUrl } from '@/constants/apiUrl'

export const usePhoneHandler = () => {
  const [phoneHandled, setPhoneHandled] = useState(false)
  const [phone, setPhone] = useState('')

  async function getPhone() {
    try {
      const res = await axiosInstance.get(
        `${apiUrl}/electronic-id/get-phone-info`,
        {
          withCredentials: true,
        }
      )

      const { phoneNumber } = res.data
      setPhone(phoneNumber)
    } catch (e) {
      devLog(e)
    }
  }

  const handlePhoneNumber = () => {
    setPhoneHandled(true)

    const element = document.getElementsByClassName(
      'eid-textbox'
    )[0] as HTMLInputElement

    const dropdown = document.getElementsByClassName(
      'iti__flag-container'
    )[0] as HTMLInputElement

    element.value = handlePhonePrefix(phone)
    element.setAttribute('pattern', '\\d*')

    element.addEventListener('paste', function (event) {
      event.preventDefault()

      let pastedData = event.clipboardData.getData('text')

      if (pastedData.charAt(0) === '0' || pastedData.charAt(0) === '+') {
        pastedData = handlePhonePrefix(pastedData.replace(/^[0+]+/, '+'))
      }

      element.value = pastedData

      element.focus()
      element.dispatchEvent(
        new Event('input', { bubbles: true, cancelable: true })
      )
    })

    element.addEventListener('keydown', function (event) {
      const key = event.key
      const ctrlOrCmd = event.ctrlKey || event.metaKey

      if (
        !/[0-9]/.test(key) &&
        !KEYS.includes(key) &&
        !(ctrlOrCmd && (key === 'c' || key === 'v' || key === 'a'))
      ) {
        event.preventDefault()
      }

      if (key === '0' && this.value.length === 0) {
        event.preventDefault()
      }
    })

    dropdown.addEventListener('click', function (event) {
      const telephoneCodeWidth = String(
        document.getElementsByClassName('eid-telephone-code')[0].clientWidth
      )

      const countryListDropdown = document.getElementsByClassName(
        'iti__country-list'
      )[0] as HTMLElement

      countryListDropdown.style.width = `${telephoneCodeWidth}px`
    })

    setTimeout(() => {
      element.focus()
      element.dispatchEvent(
        new Event('input', { bubbles: true, cancelable: true })
      )
    }, 300)
  }

  const changePhoneHandledListener = () => {
    setPhoneHandled(false)
  }

  return {
    getPhone,
    handlePhoneNumber,
    changePhoneHandledListener,
    phoneHandled,
  }
}

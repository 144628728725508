import { ContentContainer } from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '../components/App'
import { MediaPermissionsChecker } from '../components/MediaPermissionsChecker/MediaPermissionsChecker'
import {
  EID_ERROR_CODES_RETRY,
  PostStartVideoResponse,
} from '../constants/videoId'
import { actionReport } from '../methods/actionReport'
import { axiosInstance } from '../methods/axiosConfig'
import { devLog } from '../methods/devLog'
import {
  forcePathname,
  forceRedirect,
  forceReload,
} from '../methods/forceRedirect'
import { handleActionButtonsInject } from '../methods/injectHTMLVideoID'
import { VideoId } from '../styles/eid.styles'

import { VideoIdAfterCancelPopup } from '../components/VideoIdAfterCancelPopup/VideoIdAfterCancelPopup'
import { CANCEL_SURVEY_VIDEO_ID } from '../constants/cancelSurvey'
import { buttonsHandler } from '@/methods/keyboardAccessibility'
import { usePhoneHandler } from '@/hooks/usePhoneHandler'

let videoId = null
const apiUrl = process.env.WEB_API_URL

export const VideoIdPage = observer(() => {
  const store = useContext(StoreContext)

  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState
  const { currentScenarioId, context } = store.ScenarioState

  const { language } = store.TranslationsState
  const { VideoIdPage: trans } = store.TranslationsState.translations
  const cancelSurveyScreenEnabledServiceList =
    context?.cancelSurveyScreenEnabledServiceList || []

  const [isMobile, setIsMobile] = useState(false)
  const [overlayHandled, setOverlayHandled] = useState(false)
  const [eidError, setEidError] = useState('')
  const [afterClosePopupVisible, setAfterClosePopupVisible] = useState(false)
  const [isPermissionCheckSuccessful, setIsPermissionCheckSuccessful] =
    useState(false)

  async function handleContext() {
    try {
      const res = await axiosInstance.get(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/get-context`,
        {
          withCredentials: true,
        }
      )

      localStorage.setItem(
        'show-waiting-screen',
        res.data.showTransactionCompletedWaitingScreen
      )
    } catch (e) {
      devLog(e)
    }
  }

  async function handleAuth() {
    try {
      const { data } = await axiosInstance.post<PostStartVideoResponse>(
        `${apiUrl}/electronic-id/start-video`,
        {},
        {
          withCredentials: true,
        }
      )

      await getPhone()
      await handleContext()

      mountEid(data)
    } catch (e) {
      devLog(e)
    }
  }

  async function callComplete() {
    try {
      await axiosInstance.post(
        `${apiUrl}/electronic-id/complete`,
        {},
        {
          withCredentials: true,
        }
      )
    } catch (e) {
      devLog(e)
    }
  }

  const mountEid = (startVideoRes: PostStartVideoResponse): void => {
    const {
      electronicIdAuthorization: authToken,
      electronicIdDocType: docType,
      isBiometricConsentAccepted: eIDBiometricConsent,
    } = startVideoRes

    if (authToken === '') {
      devLog('missing videoid authentication')
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eid = (window as any).EID
    videoId = eid.videoId('#video', {
      lang: language,
    })

    videoId.start({
      authorization: authToken,
      idType: docType,
      eIDBiometricConsent,
    })
    videoId.on('completed', () => {
      videoId.turnOff()
      callComplete()

      forceRedirect(
        `${apiUrl}/electronic-id/redirect-from-video?result=complete`
      )
    })
    videoId.on('failed', function (error) {
      if (error) {
        const errorCode = error.error?.error || 'Unknown.Error'

        setEidError(errorCode)

        if (EID_ERROR_CODES_RETRY.includes(errorCode)) {
          return
        }

        forceRedirect(
          `${apiUrl}/electronic-id/redirect-from-video?result=fail&error=${errorCode}`
        )
      }
    })
    videoId.on('notification', async function (event) {
      await axiosInstance.post(
        `${apiUrl}/electronic-id/store-sdk-notification`,
        event,
        {
          withCredentials: true,
        }
      )
    })
  }

  const handleButtons = () => {
    buttonsHandler()
  }

  const handleOverlay = () => {
    setOverlayHandled(true)

    const overlay = document.getElementsByClassName(
      'eid-overlay'
    )[0] as HTMLInputElement

    overlay.setAttribute('id', 'overlay')
  }

  const {
    getPhone,
    handlePhoneNumber,
    changePhoneHandledListener,
    phoneHandled,
  } = usePhoneHandler()

  useInterval(
    () => {
      if (document.getElementsByClassName('eid-a').length) {
        const changeItButton = document.getElementsByClassName('eid-a')[0]
        changeItButton.addEventListener('click', changePhoneButtonListener)
      }
    },
    phoneHandled ? 300 : null
  )

  useInterval(
    () => {
      document.getElementsByClassName('eid-textbox').length &&
        handlePhoneNumber()
    },
    !phoneHandled ? 300 : null
  )

  useInterval(
    () => {
      document.getElementsByClassName('eid-overlay').length && handleOverlay()
    },
    !overlayHandled ? 300 : null
  )

  useInterval(() => {
    document.getElementsByClassName('eid-overlay').length && handleButtons()
  }, 300)

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setIsMobile(true)
    }
  }, [pageWidth])

  useEffect(() => {
    if (!eidError) return

    if (EID_ERROR_CODES_RETRY.includes(eidError)) {
      const closeOnClick = () => {
        actionReport({
          type: 'event.onboarding-web.eidv.USER_CLOSED_MANUALLY',
          payload: {},
        })
        if (
          cancelSurveyScreenEnabledServiceList.some((cs) =>
            CANCEL_SURVEY_VIDEO_ID.includes(cs)
          )
        ) {
          setAfterClosePopupVisible(true)
        } else {
          forceRedirect(
            `${apiUrl}/electronic-id/redirect-from-video?result=fail&error=${eidError}`
          )
        }
      }

      const retryOnClick = () => {
        actionReport({
          type: 'event.onboarding-web.eidv.USER_RESTARTED_MANUALLY',
          payload: {},
        })
        if (currentScenarioId !== 'videoIdRetry') {
          forcePathname('/video-id-retry')
        } else {
          forceReload()
        }
      }

      handleActionButtonsInject(trans, closeOnClick, retryOnClick)
    }

    setEidError('')
  }, [eidError])

  useEffect(() => {
    if (isPermissionCheckSuccessful) {
      handleAuth()
    }
  }, [isPermissionCheckSuccessful])

  const changePhoneButtonListener = () => {
    actionReport({
      type: 'event.onboarding-web.eidv.I_DID_NOT_RECEIVE_SMS_CLICKED',
      payload: {},
    })
    changePhoneHandledListener()
  }

  const handleClosePopup = useCallback(() => {
    forceRedirect(
      `${apiUrl}/electronic-id/redirect-from-video?result=fail&error=${eidError}`
    )
  }, [eidError])

  useEffect(() => {
    window.addEventListener('online', changePhoneHandledListener)

    return () => {
      document.removeEventListener('click', changePhoneButtonListener)
      window.removeEventListener('online', changePhoneHandledListener)
    }
  }, [])

  const renderVideoId = () =>
    isPermissionCheckSuccessful ? (
      <VideoId
        {...theme.button}
        fontFamily={theme.globals.fontFamily}
        fontFamilyHeadline={theme.globals.fontFamilyHeadline}
        videoIdPhoneChangeButton={theme.videoIdPhoneChangeButton}
        videoIdCallToActionButton={theme.videoIdCallToActionButton}
        otpInput={theme.otpInput}
        focusVisible={theme.globals.focusVisible}
      >
        <div id="video" />
      </VideoId>
    ) : (
      <MediaPermissionsChecker
        onPermissionCheckEnd={setIsPermissionCheckSuccessful}
        redirectPath="/video-id-retry"
        redirectScenario="videoIdRetry"
      />
    )

  return isMobile ? (
    <>
      <VideoIdAfterCancelPopup
        title={trans.feedbackPopupTitle}
        thankYouForYourFeedback={trans.thankYouForYourFeedback}
        sendButtonText={trans.send}
        cancelButtonText={trans.close}
        visibility={afterClosePopupVisible}
        handleVisibility={handleClosePopup}
        theme={theme}
      />
      {renderVideoId()}
    </>
  ) : (
    <ContentContainer
      {...theme.container}
      width="1000px"
      paddingMobile="0"
      marginMobile="0"
    >
      <VideoIdAfterCancelPopup
        title={trans.feedbackPopupTitle}
        thankYouForYourFeedback={trans.thankYouForYourFeedback}
        sendButtonText={trans.send}
        cancelButtonText={trans.close}
        visibility={afterClosePopupVisible}
        handleVisibility={handleClosePopup}
        theme={theme}
      />
      {renderVideoId()}
    </ContentContainer>
  )
})
